<template>
  <div class="escolher-cliente">
    <section
      v-if="isLoading"
      ref="loadingContainer"
      class="signup-customer-loading"
    >
      <spinner/>
      <h1>Buscando usuário</h1>
    </section>
    <el-card
      :body-style="{ width: '100%' }"
      class="escolher-cliente__busca">
      <h1>{{ title }}</h1>
      <busca-neopag
        v-model="suggestConsult"
        :autofocus="true"
        :title="title"
        @select="handleSelect" />
    </el-card>
    <el-alert
      v-if="showError & !suggestConsult"
      title="Erro"
      type="error"
      class="escolher-cliente__error">
      <h3>{{ message }}</h3>
      <el-button
        type="secondary"
        @click="$router.push({
          name: 'Cliente',
          params: { clientID: client_id }
        })"
      >
        <svgicon
          name="store"
          width="38"
          height="38" />
        Menu do cliente
      </el-button>
      <el-button
        type="secondary"
        @click="$router.push({ name: suggestion.routeName })">
        <svgicon
          name="money-plus"
          width="38"
          height="38" />
        {{ suggestion.label }}
      </el-button>
    </el-alert>
    <el-alert
      v-if="suggestConsult"
      :closable="false"
      title="Ah..."
      type="error"
      class="escolher-cliente__error">
      <h3>Nenhum cliente encontrado</h3>
      <el-button
        type="secondary"
        @click="$router.push({ name: 'Consulta Neopag - CPF' })">
        <svgicon
          name="search"
          width="38"
          height="38" />
        Consulta
      </el-button>
    </el-alert>
  </div>

</template>

<script>
import * as types from "@/store/types";
import BuscaNeopag from "@/components/BuscaNeopagBack";
import Spinner from "@/components/Spinner";

export default {
  components: {
    BuscaNeopag,
    Spinner
  },
  props: {
    title: {
      type: String,
      required: true
    },
    showError: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    },
    client_id: {
      type: String,
      default: ""
    },
    showConsultSuggestion: {
      type: Boolean,
      default: false
    },
    suggestion: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      searching: false,
      suggestConsult: false,
      isLoading: false
    };
  },
  watch: {
    title: function() {
    }
  },
  methods: {
    async handleSelect(item) {
      this.isLoading = true;
      this.searching = true;
      try {
        await this.$store.dispatch(types.CLIENT, item.user.id);
        await this.$store.dispatch(types.CLIENT_SET);
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.isLoading = false;
      this.searching = false;
    }
  }
};
</script>

<style lang="scss">
.escolher-cliente {
  position: relative;
.signup-customer-loading {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(255,255,255);
    z-index: 999999;
    flex: 1;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    #spinner {
      width: 80px;
      height: 80px;
    }
  }
.sale {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  height: 100%;
}
.sale-content-container {
  flex: 1;
  padding: 2rem 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes entrada {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0);
  }
}
  max-width: 720px;
  margin: 0 auto;
  animation: 0.3s entrada;
  &__busca {
    margin-top: 2rem;
    h1 {
      margin-top: 0;
    }
    .el-autocomplete {
      width: 100%;
    }
  }

  &__error {
    padding: 1rem;
    margin-top: 2rem;
    text-align: center;

    @include box-shadow(1);
    h3 {
      margin: 1rem 0;
      font-weight: 500;
      color: $laranja;
    }
    div {
      width: 100%;
    }
  }
}
</style>
