<template>
  <nav class="menu-venda-recebimento">
    <div class="user-info">
      <h4>{{ details.name }}</h4>
      <h5>Crédito disponível <strong>{{ $n(details.current_credit, 'currency') }}</strong>

        <span v-if="is_neopag_credit_config" :style="{ backgroundColor: details.is_neopag_credit ? '#98C187' : '#87BAC1', color: '#FFFFFF' }" class="tag-value">{{ details.is_neopag_credit ? 'CRÉDITO NEOPAG' : 'CRÉDITO LOJA' }}</span>

      </h5>
    </div>
    <div>
      <el-button
        :disabled="disableBackButton"
        class="button"
        type="secondary"
        @click="back"
      >
        <svgicon
          name="search"
          width="20"
          height="20"
        />
        <span class="medium-up">
          Nova busca
        </span>
      </el-button>

      <el-button
        type="secondary"
        @click="$router.push({ name: 'Cliente', params: { clientID: details.id } })">
        <svgicon
          name="store"
          width="20"
          height="20"
        />
        <span class="medium-up">
          Menu do cliente
        </span>
      </el-button>

      <el-button
        :disabled="step === 0"
        class="button"
        type="secondary"
        @click="handleReset">
        <svgicon
          name="money-plus"
          width="20"
          height="20"
        />
        <span class="medium-up">
          Recomeçar
        </span>
      </el-button>
    </div>
  </nav>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

const BACKMAP = {
  sale: {
    1: "Detalhes da venda",
    2: "Sumario da venda",
    3: "Sumario da venda",
    4: "Token da venda",
    5: "Recibo da venda",
    reset: types.SALE_RESET
  },
  payment: {
    1: "Detalhes do recebimento",
    2: "Sumario do recebimento",
    3: "Recibo do recebimento",
    reset: types.PAYMENT_RESET
  },
  deal: {
    1: "Detalhes do reparcelamento",
    2: "Sumario do reparcelamento",
    3: "Recibo do reparcelamento",
    reset: types.DEAL_RESET
  }
};

export default {
    data() {
    return {
      is_neopag_credit_config: false,
    };
  },
  computed: {
    step() {
      if (this.$route && this.$route.meta && this.$route.meta.params) {
        return this.$route.meta.params.step;
      }
      return false;
    },
    type() {
      if (this.$route && this.$route.meta && this.$route.meta.params) {
        return this.$route.meta.params.type;
      }
      return '';
    },
    disableBackButton() {
      return this.type === "sale" ? this.step === 4 : this.step === 2;
    },
    ...mapGetters({
      config: types.STORE_CONFIG,
      storeID: types.AUTH_ACTIVE_STORE,
      details: types.CLIENT_DETAILS
    })
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;

    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async handleReset() {
      if (BACKMAP[this.type]) {
        await this.$store.dispatch(BACKMAP[this.type].reset);
        this.$router.push({ name: BACKMAP[this.type][1] });
      }
    },
    async back() {
      if (BACKMAP[this.type]) {
        this.$router.push({ name: BACKMAP[this.type][1] });
        await this.$store.dispatch(types.SALE_RESET);
        await this.$store.dispatch(types.DEAL_RESET);
        await this.$store.dispatch(types.PAYMENT_RESET);
        await this.$store.dispatch(types.CLIENT_RESET);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-venda-recebimento {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 1rem;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  animation-name: entrada;
  animation-duration: 0.3s;

  .el-button {
    padding: 8px;
  }

  .medium-up {
    display: none;

    @include medium-up {
      position: relative;
      top: 1px;
      display: inline;
    }
  }

.tag-value {
  width: 120px;
  border-radius: 60px;
  padding: 4px 8px;
  font-size: 0.6rem;
  margin-top: 4px;
  margin-left: 4px;
  font-weight: 700;
}
  .user-info {
    h4 {
      margin-bottom: 4px;
      font-weight: 700;
    }
    h5 {
      strong {
        font-weight: 700;
      }
    }
  }
}

@keyframes entrada {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>
