<template>
  <el-autocomplete
    ref="searchInput"
    :autofocus="autofocus"
    :placeholder="placeholder"
    v-model="selection"
    :fetch-suggestions="querySearch"
    :trigger-on-focus="false"
    popper-class="busca-neopag__autocomplete"
    class="busca-neopag"
    @select="item => handleSelect(item)"
  >
    <i class="el-icon-search el-input__icon" />
    <template slot-scope="props">
      <div
        :class="{
          '--disabled': cantSelect(props.item.store_status.id)
        }"
        class="busca-neopag__cliente"
        :style="cantSelectSale(props.item.store_status.id)"
      >
        <div class="busca-neopag__perfil">
          <radial-progress
            :diameter="70"
            :stroke-width="6"
            :completed-steps="userPercentageScore(props.item.user.user_score)"
            :total-steps="100"
            :start-color="userPercentageColor(props.item.user.user_score)"
            :stop-color="userPercentageColor(props.item.user.user_score)"
            inner-stroke-color="rgba(108, 115, 124, 0.3)"
          >
            <div class="avatar" v-if="props.item.user.selfie">
              <img :src="props.item.user.selfie || $avatar" alt="selfie" />
            </div>
            <div class="generated-avatar" v-else>
              <image-avatar :name="props.item.user.name" />
            </div>
          </radial-progress>
          <div class="info" style="margin-left: 1rem;">
            <span v-if="!actionless" class="info__name">{{ props.item.user.name }}</span>
            <span v-else class="info__name">{{ props.item.user.name }}</span>
            <span class="info__cpf">
              <!-- <svgicon
                :class="props.item.user.phone ? '' : 'disabled'"
                name="cell"
                @click.native="contact('Celular', props.item.user.phone)"
              />
              <svgicon
                :class="props.item.user.email ? '' : 'disabled'"
                name="email"
                @click.native="contact('Email', props.item.user.email)"
              />-->
              CPF: {{ props.item.user.cpf | toCPF }}
            </span>
          </div>
        </div>
        <div v-if="!actionless" class="busca-neopag__actions">
          <div class="cashier">
            <div class="cashier__sale">
              <el-tooltip
                v-if="cantSell(props.item.store_status.id)"
                :content="props.item.store_status.description"
                effect="dark"
                placement="bottom"
                :trigger="$isMobile ? 'click': 'hover'"
              >
                <i
                  v-if="$router.history.current.name === 'Detalhes da venda'"
                  class="el-icon-warning"
                  style="font-size: 30px; color: #ffd16e;"
                ></i>
                <!-- <el-button
                  :class="{
                    'invalid': cantSell(props.item.store_status.id)
                  }"
                  icon="el-icon-question"
                  size="mini"
                  type="secondary"
                >Vender</el-button>-->
              </el-tooltip>
              <!-- <el-button
                v-else
                size="mini"
                type="secondary"
                icon="el-icon-goods"
                @click="() => handleForceNavigation('venda', props.item.user.id)"
              >Vender</el-button>-->
            </div>
            <div class="cashier__payment">
              <!-- <el-button
                size="mini"
                type="secondary"
                icon="el-icon-sold-out"
                @click="handleForceNavigation('recebimento', props.item.user.id)"
              >Receber</el-button>-->
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-autocomplete>
</template>

<script>
import throttle from "lodash/throttle";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import toCPF from "@/helpers/toCPF";
import phone from "@/helpers/phone";
import RadialProgress from "vue-radial-progress";
import ImageAvatar from "@/components/ImageAvatar";

export default {
  components: {
    RadialProgress,
    ImageAvatar
  },
  filters: {
    toCPF,
    toPhone: phone.format
  },
  props: {
    actionless: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Digite um nome, cpf ou celular..."
    },
    value: {
      type: Boolean,
      default: false
    },
    allowedStatus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selection: "",
      notAllowed: "not-allowed"
    };
  },
  computed: {
    ...mapGetters({
      list: types.CLIENT_LIST,
      storeInfo: types.STORE_INFO,
      storeID: types.AUTH_ACTIVE_STORE,
      scoreNeopag: types.CLIENT_SCORE_NEOPAG
    })
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
  methods: {
    cantSelectSale(id) {
      if (
        this.$router.history.current.name === "Detalhes da venda" &&
        this.cantSell(id)
      ) {
        return { cursor: this.notAllowed };
      }
    },
    userPercentageScore(user_score) {
      if (user_score.current_score) {
        const score = (user_score.current_score * 100) / 60000; // percentage score where 60.000 is equal to 100%;
        return score;
      }
      return 0;
    },
    userPercentageColor(user_score) {
      if (user_score.level) {
        if (user_score.level <= 2) {
          return "rgba(151, 4, 12, 0.7)";
        } else if (user_score.level > 2 && user_score.level <= 5) {
          return "rgba(253, 152, 39, 0.7)";
        } else {
          return "rgba(108, 167, 84, 0.7)";
        }
      }
      return "rgba(151, 4, 12, 0.7)";
    },
    cantSell(statusID) {
      if (this.storeInfo.legacy_mode) {
        return [1, 5, 10, 11].indexOf(statusID) < 0;
      }
      return statusID !== 1;
    },
    cantSelect(statusID) {
      if (!this.storeInfo.legacy_mode && this.allowedStatus.length) {
        return this.allowedStatus.indexOf(statusID) < 0;
      }
      return false;
    },
    notifyCopy(success, type) {
      this.$notify[success ? "success" : "error"]({
        title: success ? "Sucesso!" : "Erro!",
        message: success ? `${type} copiado` : "não foi possível copiar!"
      });
    },
    fallbackCopyTextToClipboard(type, value) {
      const fake = document.createElement("textarea");
      fake.value = value;
      document.body.appendChild(fake);
      fake.focus();
      fake.select();
      try {
        var successful = document.execCommand("copy");
        this.notifyCopy(successful, type);
      } catch (err) {
        this.notifyCopy(false, type);
      }
      document.body.removeChild(fake);
    },
    contact(type, value) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(type, value);
      } else {
        navigator.clipboard.writeText(value).then(
          () => {
            this.notifyCopy(true, type);
          },
          () => {
            this.notifyCopy(false, type);
          }
        );
      }
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    handleSelect(item) {
      if (event && event.srcElement.tagName === "BUTTON") return;

      //if (this.cantSelect(item.store_status.id)) {
      //  return false;
      //}

      this.selection = item.user.name || item.user.cpf || item.user.phone || "";
      if (this.$router.history.current.name === "Recebimento") {
        this.handleForceNavigation("recebimento", item.user.id);
      }
      if (this.$router.history.current.name === "Recebimento Boleto") {
        this.handleForceNavigation("recebimento-boleto", item.user.id);
      }
      if (this.$router.history.current.name === "Clientes") {
        this.$emit("select", item);
      }
      if (this.$router.history.current.name === "Detalhes do reparcelamento") {
        this.$emit("select", item);
      }
      if (!this.cantSell(item.store_status.id)) {
        this.$emit("select", item);
      }
      if (
        this.$router.history.current.name === "Detalhes da venda" &&
        this.cantSell(item.user.id)
      ) {
        return false;
      }
    },
    async handleForceNavigation(path, userID) {
      try {
        await this.$store.dispatch(types.CLIENT, userID);
        await this.$store.dispatch(types.CLIENT_SET);
        await this.$store.dispatch(types.CLIENT_PRESERVE_SET, true);
        if (path === "recebimento" || path === "recebimento-boleto") {
          return this.$router.push({ path: `/${path}/detalhes` });
        }
        this.$router.push({
          path: `/${path}`
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    querySearch: throttle(async function(str, cb) {
      await this.$store
        .dispatch(types.CLIENT_SEARCH, {
          storeID: this.storeID,
          q: str
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      cb(this.list);
    }, 200)
  }
};
</script>
<style lang="scss">
.busca-neopag {
  &__autocomplete {
    .el-autocomplete-suggestion__wrap.el-scrollbar__wrap {
      max-height: 60vh;
    }
  }

  &__cliente {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.75rem 0;
    margin: 0;
    * {
      box-sizing: content-box;
    }
    @include medium-up {
      height: 4rem;
    }

    &.--disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__perfil,
  &__actions {
    flex: 0 auto;
  }

  &__perfil {
    display: flex;
    flex-flow: row wrap;

    @include medium-up {
      flex: 1;
      width: auto;
    }

    .avatar,
    .score,
    .info {
      flex: 0 auto;
    }

    .avatar {
      display: flex;
      flex: 0 40px;
      height: 40px;
      margin: auto 0;
      background-color: $base-background;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;
      border-radius: 50%;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }
      @include box-shadow(1);
    }

    .score,
    .info {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      min-width: 0;

      &__nivel,
      &__name,
      &__cpf {
        flex: 100%;
      }

      &__nivel {
        padding-bottom: 0.5rem;
        font-family: $title-font;
        font-size: 0.65rem;
        line-height: 0.85rem;
        color: $preto;
      }

      &__name {
        padding-right: 0.5rem;
        margin-bottom: 0.5rem;
        overflow: hidden;
        font-family: $title-font;
        font-size: 0.85rem;
        font-weight: 700;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__cpf {
        font-family: $base-font;
        font-size: 0.85rem;
        font-weight: 400;
        line-height: 28px;
        .svg-icon {
          @include medium-down {
            display: none;
          }
          width: 28px;
          height: 26px;
          margin: 0;
          margin-right: 0.25rem;
          cursor: pointer;
          transition: all 0.3s;
          &.disabled {
            cursor: not-allowed;
            box-shadow: none !important;
            opacity: 0.5;
          }
          &:hover {
            @include box-shadow(2);
          }
          &:active {
            @include box-shadow(1);
          }
        }
      }
    }

    .score {
      flex: 0 60px;
      width: 60px;
      padding: 0 1rem;
      text-align: center;
      @include medium-down {
        display: none;
      }
    }

    .info {
      flex: 1;
    }
  }

  &__actions {
    display: flex;
    flex-flow: column wrap;

    @include medium-up {
      flex: 0 98px;
      width: 98px;
    }

    @include medium-down {
      height: 40px;
    }

    .cashier {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      height: 100%;

      @include medium-down {
        flex-flow: row wrap;
        margin-top: 0.5rem;
      }

      &__sale,
      &__payment {
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;

        @include medium-down {
          flex: 0 50%;
        }
      }

      .el-button {
        width: 100%;
        height: 0.75rem;
        span {
          pointer-events: none;
        }

        &.invalid {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
      .quick {
        width: 100%;
        height: 0.75rem;
        margin-top: 0.5rem;
        font-family: $title-font;
        font-size: 0.75rem;
        line-height: 0.75rem;
        text-align: center;
      }
    }
  }
}
</style>
