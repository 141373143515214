<template>
  <Lottie
    :options="defaultOptions"
    :height="$isMobile ? 150 : 250"
    :width="$isMobile ? 150 : 250"
    v-on:animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "@/assets/loading.json";
export default {
  components: {
    Lottie
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
        path: "../../assets/loading.json",
        loop: false,
        autoplay: true,
        render: "svg"
      },
      animationSpeed: 1
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    }
  }
};
</script>

<style>
</style>
